import React, { useContext, useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout, { getFirebaseToken, isBrowser, UserContext, withLayout } from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import styles from "./account.module.css"

import firebase from 'firebase';
import { Button, Typography } from '@material-ui/core'
import { navigate } from "@reach/router"
import { CourseCard } from './courses'

if (isBrowser) {
  var uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'redirect',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        let url = new URL(window.location.href);
        var c = url.searchParams.get("redirectOnSuccess");
        if (c) {
          navigate("/" + c);
        } else {
          return false;
        }


      },
    },
  };
}




const Page: React.FC = (props: PageProps<GatsbyTypes.AccountPageQuery>) => {
  let userContext = useContext(UserContext);
  console.log(userContext);
  const [coursesUserOwns, setCoursesUserOwns] = useState<string[]>([])


  useEffect(() => {
    async function getCourses() {
      let token = await getFirebaseToken();
      let courses = await getCoursesUserHas(token);
      setCoursesUserOwns(courses);

    }
    getCourses();

  }, [userContext?.loggedIn])

  if (userContext?.loggedIn || !isBrowser) {
    let currentUser = null;
    if (isBrowser) {
      currentUser = firebase.auth().currentUser;
    }

    return (
      <>
        <h1 className={styles.header}>My Account</h1>
        <h2>Welcome <span className={styles.userName}>{currentUser?.displayName} </span> </h2>
        <Button variant="contained" color="secondary" className={styles.logoutButton} onClick={() => {
          if (isBrowser) {
            firebase.auth().signOut();
          }
        }}>
          Logout
        </Button>
        <a href="mailto:help@leanqualityacademy.com"> <Button variant={"contained"} className={styles.getHelpButton} onClick={() => {
          alert("You can get help by emailing us at help@leanqualityacademy.com");
        }}>Get Help</Button></a>

        {coursesUserOwns && coursesUserOwns.length > 0 ? <h2>Courses you own</h2> : null}

        {coursesUserOwns?.map((course) => {
          let userCourse = props.data.allContentfulCourse.edges.find(edge => edge.node.slug == course);
          // console.log(userCourse, course, props.data.allContentfulCourse.edges[0].node.slug);


          if (userCourse) {
            return <CourseCard course={userCourse.node} key={userCourse.node.slug}/>
          }
        })}

      </>
    )

  } else {

    return (
      <>
        <h1 className={styles.header}>Login</h1>
        <h2>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </h2>
      </>
    )
  }

}


export default withLayout(Page);





export const pageQuery = graphql`
query AccountPage {
 allContentfulCourse {
    edges{
      node{
        slug
        title
        image{
          fluid(maxWidth: 1600) {
              src
          }
        }
      }
    }
  }
}
`

async function getCoursesUserHas(idToken): Promise<string[]> {

  let res = await fetch(`/.netlify/functions/allCoursesUserHas?id=${idToken}`);
  let data = await res.json();
  if (data.status == 200) {
    return data.courses;
  }
  else {
    return []
  }

}