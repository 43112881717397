import React, { ReactElement, useContext } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout, { isBrowser, UserContext, withLayout } from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import styles from "./courses.module.css"

import firebase from 'firebase';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'



import { navigate } from '@reach/router';

const Courses: React.FC = (props: PageProps<GatsbyTypes.CoursesQuery>) => {
  console.log(props.data.allContentfulCourse)
  return (
    <>
      <h1 className={styles.header}>Courses</h1>

      {props.data.allContentfulCourse.edges.map(course =>
        <CourseCard key={course.node.slug} course={course.node} />
      )}

    </>
  );
}

export default withLayout(Courses);





interface CourseCardProps {
  course:GatsbyTypes.ContentfulCourse,
}

export function CourseCard({ course }: CourseCardProps): ReactElement {
  return (
    <div key={course.slug}>
      <Card className={styles.root} onClick={() => { navigate(`/${course.slug}`) }}>
        <CardActionArea>
          <CardMedia
            className={styles.media}
            image={course.image?.fluid?.src}
            title="Course Background Image"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {course.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {course.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Link to={`/${course.slug}`}>
            <Button size="small" color="primary">
              Go to course
            </Button>
          </Link>

          {/* <Button size="small" color="primary">
                Learn More
              </Button> */}

        </CardActions>
      </Card>
    </div>
  )
}



export const pageQuery = graphql`
query Courses  {
  allContentfulCourse {
    edges{
      node{
        slug
        title
        image{
          fluid(maxWidth: 1600) {
              src
          }
        }
      }
    }
  }
}
`
